<template>
  <v-autocomplete
    :value="value"
    label="Estado"
    :items="states"
    item-text="name"
    item-value="id"
    :loading="loading"
    :disabled="loading"
    :error-messages="errorMessages"
    autocomplete="nope"
    hide-details="auto"
    @input="(e) => $emit('input', e)"
  />
</template>

<script>
  import statesApi from '@/api/states'

  export default {
    props: {
      value: {
        type: Number,
        default: 0,
      },
      errorMessages: {
        type: Array,
        default: () => {},
      },
    },

    data () {
      return {
        states: [],
        loading: false,
      }
    },

    mounted () {
      this.loadStates()
    },

    methods: {

      async loadStates () {
        try {
          this.loading = true

          const response = await statesApi.list()

          this.states = response.data.states
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

    },

  }
</script>
